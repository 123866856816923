import Vue from 'vue'
import VueRouter from 'vue-router'

import FrontLayout from '@/components/Layout/layout'
Vue.use(VueRouter)
const router = new VueRouter({
    mode: 'history',
    linkActiveClass:'active',
    routes:[
        {
            path: '/',
            component: FrontLayout,
            children:[
                {
                    path: '/',
                    component : ()=> import('./pages/Dashboard/dashboard'),
                    name: 'Dashboard'
                },
                {
                    path: '/user',
                    component : ()=> import('./pages/Users/user'),
                    name: 'User'
                },
                {
                    path: '/rootvariables',
                    component : ()=> import('./pages/RootVariables/RootVariables'),
                    name: 'RootVariables'
                }
            ]

        },
        {
            path : '/login',
            component: ()=> import('@/components/login/login'),
            name: 'Login',
            meta:{
                public:true
            }
        },
        {
            path: "*",
            component: ()=> import('./pages/404/404'),
            name: "404"
        }
    ]
})
export default router