<template>
  <div id="app">
   <router-view></router-view>
    <loader v-if="$store.state.loader"></loader>
  </div>
</template>

<script>

import Loader from '@/components/Loader/loader'
export default {
  name: 'App',
  components:{
    Loader
  },
  data(){
    return {
      loader: false

    }
  }
}
</script>

<style>

</style>
