<template>
<div class="sidebar-view">
  <div class="sidebar-logo">
    <img src="../../../public/img/_avatar180.jpg" alt="" >
  </div>
  <el-menu  class="el-menu-vertical-demo" background-color="#222326"  text-color="#fff"
           active-text-color="#ffd04b">
  <router-link to="/">
    <el-menu-item index="1">
      <i class="el-icon-menu"></i>
      <span slot="title">Dashboard</span>
    </el-menu-item>
  </router-link>
    <router-link to="/user">
      <el-menu-item index="2">
        <i class="el-icon-user"></i>
        <span slot="title">Users</span>
      </el-menu-item>
    </router-link>
    <router-link to="/rootvariables">
      <el-menu-item index="3">
        <i class="el-icon-setting"></i>
        <span slot="title">RootVariables</span>
      </el-menu-item>
    </router-link>

  </el-menu>
</div>
</template>

<script>
export default {
  name: "sidebar"
}
</script>

<style scoped>
.el-menu{
  border: none;
  margin-top: 30px;
}
.el-menu-item [class^=el-icon-] {
font-size: 24px;
}
.el-menu-item, .el-submenu__title{}
.el-menu-item{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  padding: 5px 10px;
}
.router-link-exact-active{
  text-decoration: none !important;
}
.router-link-exact-active.active .el-menu-item i,
.router-link-exact-active.active .el-menu-item span{
  color: rgb(255, 208, 75) !important;
}
a{
  text-decoration: none !important;
}
</style>
