<template>
<div class="header-view">
<div class="user">
  <el-dropdown>
      <span class="el-dropdown-link">
        Admin<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item icon="el-icon-plus" @click.native="logOut">Log Out</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>
</div>
</template>

<script>
export default {
  name: "header",
  data(){
    return{

    }
  },
  methods:{
    logOut(){
        this.$router.push('/login');
    }
  },
 computed:{
   userName() {
     return localStorage.getItem('userName');
   },
 }
}
</script>

<style scoped>
.user{
  font-size: 20px;
  text-transform: uppercase;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-dropdown-link{
 padding: 20px;
}
</style>
