<template>
<div class="front-view">
  <Sidebar/>
  <Header/>
  <router-view></router-view>
</div>
</template>

<script>
import  Sidebar from '../Sidebar/sidebar';
import  Header from '../Header/header'
export default {
name: "layout",
  components:{
    Sidebar,
    Header
  }
}
</script>

<style scoped>

</style>