import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        loader:false
    },
    mutations:{
        loader(state,value){
            state.loader = value
        }
    },
    actions:{},
    getters:{}
})

export default store