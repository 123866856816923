import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Element from "element-ui";
import http from "./plugins/Axios";
import Vuex from 'vuex';
import locale from "element-ui/lib/locale";
import helpers from './mixins/helper';
import './mixins/filter';
import Vuelidate from 'vuelidate'
import enLang from "element-ui/lib/locale/lang/en";
// import ruLang from "element-ui/lib/locale/lang/ru-RU"
import "element-ui/lib/theme-chalk/index.css";
import JsonExcel from "vue-json-excel";

import router from './router';
import store from "./store";

Vue.config.productionTip = false
Vue.prototype.$http = http;

Vue.component("downloadExcel", JsonExcel);
Vue.use(Element);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.mixin(helpers);
Vue.use(Vuelidate);


locale.use(enLang)




new Vue({
  el:'#app',
  router,
  store,
  render: h => h(App)
})
